import React, { Component } from "react";
import ClockBackground from '../images/header/Clock-Background-min.png';

export default class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date()
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      const currentTime = new Date();
      const adjustedHour = currentTime.getHours() + (currentTime.getMinutes() / 60);
      this.setState({
        time: currentTime,
        adjustedHour: adjustedHour
      });
    }, 1000);
  }

  componentWillMount() {
    clearInterval(this.timerId);
  }

  render() {
    return (
      <div className="clock" style={{ backgroundImage: `url(${ClockBackground})` }}>
        <div
          className="hour_hand"
          style={{
            transform: `rotateZ(${this.state.adjustedHour * 30}deg)`
          }}
        />
        <div
          className="min_hand"
          style={{
            transform: `rotateZ(${this.state.time.getMinutes() * 6}deg)`
          }}
        />
        <div
          className="sec_hand"
          style={{
            transform: `rotateZ(${this.state.time.getSeconds() * 6}deg)`
          }}
        />
      </div>
    );
  }
}