import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import "./styles/Journal.css"

import background from "./images/backgrounds/book-paper-1200.jpg";

function People() {


   return (
      <div className="journal">
            
            <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
               <TabList>
                  <Tab>Task List</Tab>
                  <Tab>Personal Notes</Tab>
                  <Tab>Team Journal</Tab>
               </TabList>

               <TabPanel>
               {/* <div className="tabGuts" style={{ backgroundImage: `url(${background})` }}> */}
                  <div className="tabGuts">
                     <p>This is Text.</p>
                  </div>
               </TabPanel>

               <TabPanel>
                  <div className="tabGuts">

                  </div>
               </TabPanel>
               
               <TabPanel>
                  <div className="tabGuts">
                     <iframe title="Team Journal" className="team-journal" src="https://docs.google.com/document/d/125vHfRSUW9qXxuRC09_zihyc2cl34S689sVJW0VYYpg/edit#"></iframe>
                  </div>
               </TabPanel>

            </Tabs>
            
         </div>
      );
   }
export default People;