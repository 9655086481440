import React from "react";
import "./styles/Footer.css";
import FooterBackground from './images/footer/FooterPipes2-md.png';
import FooterLogout from './images/footer/Button-Exit1.png';

import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

firebase.initializeApp({
   apiKey: "AIzaSyC2HlAJzW1rIeMeFxDqYXfjHDdcqI-qiJc",
   authDomain: "roll-4-keeps-website.firebaseapp.com",
   projectId: "roll-4-keeps-website",
   storageBucket: "roll-4-keeps-website.appspot.com",
   messagingSenderId: "350222653387",
   appId: "1:350222653387:web:cd10851fa359d11d53c84e",
   measurementId: "G-B2SREMGXEF"
})

function Footer() {
   const auth = firebase.auth();
   const [user] = useAuthState(auth);
   
   function SignIn() {
   
      const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
      }
    
      return (
        <>
          <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
          <p>Do not violate the community guidelines or you will be banned for life!</p>
        </>
      )
    
   }
    
   function SignOut() {
      return auth.currentUser && (

         <img onClick={() => auth.signOut()} className="footerLogout" src={FooterLogout} alt="" />

      )
   }
   return (
      <div className="footer" style={{ backgroundImage: `url(${FooterBackground})` }}>
         <div className="flex row-center FooterPanel">
            <div className="mx-10">
               
            </div>
         </div>
         <SignOut />
      </div>
      );
   }
export default Footer;