import React, { useState } from "react";
import { render } from "react-dom";
import { Offcanvas, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

import InventoryModal from "../modals/InventoryModal";
import JournalModal from "../modals/JournalModal";
import MapsModal from "../modals/MapsModal";

// import BookmarkMessages from '../images/header/Bookmark-Messages.png';
import BookmarkInventory from '../images/header/Bookmark-Inventory.png';
import BookmarkJournal from '../images/header/Bookmark-Journal.png';
import BookmarkMaps from '../images/header/Bookmark-Maps.png';

// import Chat from "../Chat";
// import BodyBackground from "../images/backgrounds/book-paper-1200.jpg";

// function Sidebar({ name, ...props }) {
//    const [show, setShow] = useState(false);
 
//    const handleClose = () => setShow(false);
//    const handleShow = () => setShow(true);
 
//    return (
//      <>
//      <img 
//             onClick={handleShow} 
//             className="bookmark" 
//             src={BookmarkMessages} 
//             alt="Nav item opens the Inventory Panel" />
//          <Offcanvas show={show} onHide={handleClose} {...props}>
//             {/* <Offcanvas.Header closeButton  style={{ backgroundImage: `url(${HeadBackground})` }}>
//                <Offcanvas.Title>Sidebar Header</Offcanvas.Title>
//             </Offcanvas.Header> */}
//          <Offcanvas.Body style={{ backgroundImage: `url(${BodyBackground})` }}>
//          <div className="width-100 height-100">
//            <Chat />
//            </div>
//             </Offcanvas.Body>
//          </Offcanvas>
//       </>
//    );
//  }
 
//  function Example() {
//    return (
//      <>
//        {['start', 'end', 'top', 'bottom'].map((placement, idx) => (
//          <Sidebar key={idx} placement={placement} name={placement} />
//        ))}
//      </>
//    );
//  }
 

function BookmarkNav() {
   const [inventoryShow, setInventoryShow] = useState(false);
   const [journalShow, setJournalShow] = useState(false);
   const [mapsShow, setMapsShow] = useState(false);
 
   return (
      <>
      <div className="bookmarkBlock d-flex flex-row">
         {/* <Sidebar /> */}
         <div class="bookmark-slide">
            <img 
               onClick={() => setInventoryShow(true)} 
               className="bookmark" 
               src={BookmarkInventory} 
               alt="Nav item opens the Inventory Panel" />
         </div>
         <div class="bookmark-slide">
            <img 
               onClick={() => setJournalShow(true)} 
               className="bookmark" 
               src={BookmarkJournal} 
               alt="Nav item opens the Journal Panel" />
         </div>
         <div class="bookmark-slide">
            <img 
               onClick={() => setMapsShow(true)} 
               className="bookmark" 
               src={BookmarkMaps} 
               alt="Nav item opens the Maps Panel " />
         </div>
      </div>
       
      <Modal
         show={inventoryShow}
         onHide={() => setInventoryShow(false)}
         dialogClassName="main-modal"
         centered
         restoreFocus
      >
         <Modal.Body>
            <InventoryModal />
         </Modal.Body>
      </Modal>
       
      <Modal
         show={journalShow}
         onHide={() => setJournalShow(false)}
         dialogClassName="main-modal"
         centered
         fullscreen="true"
      >
         <Modal.Body>
            <JournalModal />
         </Modal.Body>
      </Modal>

      <Modal
         show={mapsShow}
         onHide={() => setMapsShow(false)}
         dialogClassName="main-modal"
         centered
      >
         <Modal.Body>
            <MapsModal />
         </Modal.Body>
      </Modal>
      </>
   );
 }
 
export default BookmarkNav;