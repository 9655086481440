import React from 'react';
import './styles/DragDrop.css';
import CharacterLeft from './panels/CharacterLeft';
import CharacterRight from './panels/CharacterRight';

const DragDrop = () => {
  return (
    <div>
      <div className="d-flex flex-row align-content-start justify-content-between">
        <CharacterLeft drag={drag} drop={drop} allowDrop={allowDrop} />
        <CharacterRight drag={drag} drop={drop} allowDrop={allowDrop} />
      </div>
    </div>
  );
};

function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  var draggedElement = document.getElementById(data);
  
  if (!draggedElement.getAttribute("data-card-type")) {
    alert("That's not a card!");
    return;
  }

  var cardType = draggedElement.getAttribute("data-card-type");
  var acceptType = ev.target.getAttribute("data-accept-type");
  
  if (cardType === acceptType) {
    ev.target.appendChild(draggedElement);
  } else {
    alert("That's . . . not where that goes.");
  }
}

export default DragDrop;
