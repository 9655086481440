import React from "react";
import "./styles/Header.css";

import HeaderBackground from './images/header/Header-Background-200-min.jpg';
import MetalSheetLeft from './images/header/nav-metal-left2-md-min.png';
import MetalSheetRight from './images/header/nav-metal-right2-md-min.png';
import HeaderCenterTop from './images/header/HeaderCenter-Top-5-min.png';
import BookmarkNav from './MainNav/BookmarkNav';
import PipeNav from './MainNav/PipeNav';

// clock stuff
import Clock from "./MainNav/Clock";
import "./styles/Clock.css";

const Header = () => (
   <div className="header" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL
         + "/images/header/Header-Background.jpg"})`,
         backgroundRepeat: "no-repeat"
      }} >
      
      {/* actual header content */}

      <div className="flex">
         
         {/* static images */}
         
         <div className="woodenheader" style={{ backgroundImage: `url(${HeaderBackground})` }}>
         </div>
         <img className="metalsheet metalsheetleft" src={MetalSheetLeft} alt="" />
         <img className="metalsheet metalsheetright" src={MetalSheetRight} alt="" />
         
         {/* navigation elements */}
         
         <div className="navbar-left">
            <BookmarkNav />
         </div>
         <div className="navbar-center">
            <div className="header-center">
               <img className="HeaderCenterTop" src={HeaderCenterTop} alt="Adventures In Drenwal" />
            </div>
         </div>
         <div className="navbar-right">
            <Clock />
            <PipeNav />
         </div>

      </div>

      {/* end of actual header content */}

   </div>
);

export default Header;