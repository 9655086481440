import React from 'react';
import cardImages from '../cardImages';
import cardTitles from '../cardTitles';

const CharacterRight = ({ drag, drop, allowDrop, resetDrag }) => {
  return (
    <div id="CharacterRight" className="CharacterBox d-flex flex-column align-self-start">
      <div id="BackpackEquipment" className="d-flex flex-column align-self-start mb-3">
        <div className="mb-2">
          <img src={cardTitles.titles.equipment} draggable="false" width="175" alt="" />
        </div>
        <div id="backpack" onDragLeave={resetDrag}>
        {/* head cards */}
          <img src={cardImages.cards.equipment.head.helmet1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Helmet1" data-card-type="head" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.equipment.head.magicViking} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Helmet1" data-card-type="head" className="card-holder-vertical" alt="" />
        {/* body */}
          <img src={cardImages.cards.equipment.body.leather1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Armor1" data-card-type="body" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.equipment.body.plate2} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Armor2" data-card-type="body" className="card-holder-vertical" alt="" />
        {/* feet */}
          <img src={cardImages.cards.equipment.feet.boots1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Shoes1" data-card-type="feet" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.equipment.feet.boots2} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Shoes2" data-card-type="feet" className="card-holder-vertical" alt="" />
        {/* ring */}
          <img src={cardImages.cards.equipment.ring.fire1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Ring1" data-card-type="ring" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.equipment.ring.air1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="Ring2" data-card-type="ring" className="card-holder-vertical" alt="" />
        </div>
      </div>
      <div id="BackpackWeapons" className="d-flex flex-column align-self-start">
        <div className="mb-2">
          <img src={cardTitles.titles.weapons} draggable="false" width="175" alt="" />
        </div>
        <div id="backpack">
        {/* main hand */}
          <img src={cardImages.cards.weapons.handMain.shortSword1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandMain1" data-card-type="handMain" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.weapons.handMain.battleAxe1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandMain2" data-card-type="handMain" className="card-holder-vertical" alt="" />
        {/* off hand */}
          <img src={cardImages.cards.weapons.handOff.shieldBuckler1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandOff1" data-card-type="handOff" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.weapons.handOff.shieldBuckler2} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandOff2" data-card-type="handOff" className="card-holder-vertical" alt="" />
        {/* two handed */}
          <img src={cardImages.cards.weapons.handTwo.shortBow1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandTwo1" data-card-type="handTwo" className="card-holder-vertical" alt="" />
          <img src={cardImages.cards.weapons.handTwo.crossbow1} draggable="true" onDragStart={(ev) => drag(ev)} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} id="HandTwo2" data-card-type="handTwo" className="card-holder-vertical" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CharacterRight;
