import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

import CalendarModal from "../modals/CalendarModal";
import PeopleModal from "../modals/PeopleModal";
import ReferenceModal from "../modals/ReferenceModal";

import PipesClock from '../images/header/PipeFramed-0-Clock-min.png';
import PipesCalendar from '../images/header/PipeFramed1-min.png';
// import PipesPeople from '../images/header/PipeFramed2-min.png';
// import PipesReference from '../images/header/PipeFramed3-min.png';


function PipeNav() {
   const [calendarShow, setCalendarShow] = useState(false);
   const [peopleShow, setPeopleShow] = useState(false);
   const [referenceShow, setReferenceShow] = useState(false);
 
   return (
      <>
      <div className="d-flex align-content-start header-pipes">
         <img 
            className="header-clock" 
            src={PipesClock} 
            alt="Nav item opens the Calendar Panel" />
         <img 
            onClick={() => setCalendarShow(true)} 
            className="header-pipe" 
            src={PipesCalendar} 
            alt="Nav item opens the Calendar Panel" />
         {/* <img 
            onClick={() => setPeopleShow(true)} 
            className="header-pipe" 
            src={PipesPeople} 
            alt="Nav item opens the People/Team Panel" />
         <img 
            onClick={() => setReferenceShow(true)} 
            className="header-pipe" 
            src={PipesReference} 
            alt="Nav item opens the Reference Panel " /> */}
      </div>
       
      <Modal
         show={calendarShow}
         onHide={() => setCalendarShow(false)}
         dialogClassName="main-modal"
         centered
         restoreFocus
      >
         <Modal.Body>
            <CalendarModal />
         </Modal.Body>
      </Modal>
       
      <Modal
         show={peopleShow}
         onHide={() => setPeopleShow(false)}
         dialogClassName="main-modal"
         centered
         fullscreen="true"
      >
         <Modal.Body>
            <PeopleModal />
         </Modal.Body>
      </Modal>

      <Modal
         show={referenceShow}
         onHide={() => setReferenceShow(false)}
         dialogClassName="main-modal"
         centered
      >
         <Modal.Body>
            <ReferenceModal />
         </Modal.Body>
      </Modal>
      </>
   );
 }
 
export default PipeNav;