import logo1 from './images/Roll4Keeps-die-bk.svg';
import died4 from './images/thinDie-wh.svg';
import React from "react";
import "./styles/Home.css";

function Home() {

  return (
    <div className="home">
      {/* <img src={logo1} className="logo-r4k dead-pointer stay-boy" alt="the words 'roll 4 keeps' in front of a 4-sided die" /> */}

      <a href="/" className="r4k"><img src={logo1} className="logo-r4k dead-pointer stay-boy" alt="the words 'roll 4 keeps' in front of a 4-sided die" /></a>

      <img src={died4} className="logo-d4 dead-pointer stay-boy spin-2" alt="the Drenwal shield crest with a crown above a crossed axe, sword, and wrench" />
    </div>
  );
}

export default Home;
