import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import "./styles/Reference.css"

import background from "./images/backgrounds/book-paper-1200.jpg";

function Reference() {


   return (
      <div className="journal">
            
            <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
               <TabList>
                  <Tab>Homebrew Rules</Tab>
                  <Tab>System Resource Document</Tab>
                  <Tab>Misc Yada Yada</Tab>
               </TabList>

               <TabPanel>
               {/* <div className="tabGuts" style={{ backgroundImage: `url(${background})` }}> */}
                  <div className="tabGuts">
                     <p>This is Text.</p>
                  </div>
               </TabPanel>

               <TabPanel>
                  <div className="tabGuts">

                  </div>
               </TabPanel>
               
               <TabPanel>
                  <div className="tabGuts">

                  </div>
               </TabPanel>

            </Tabs>
            
         </div>
      );
   }
export default Reference;