import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Journal from "./Journal";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";

import background from "./images/backgrounds/Drenwal-World-Map-v7_3-frameless.jpg";
import "./styles/App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="app bkg-centered" style={{ backgroundImage: `url(${background})` }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/dashboard/*" element={<Dashboard />} >
            <Route path='journal' element={<Journal />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
