import React from "react";
import 'react-tabs/style/react-tabs.css';
import "./styles/Journal.css"

function Calendar() {

   return (
      <div className="journal">
         <div className="tabGuts">
            <div className="modalIframeOverrides1">
               <div className="modalIframeOverrides2">
                  <iframe className="modalIframeOverrides3" title="Drenwal, Map of The World" frameborder="0" width="" height="" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FOEMeS8p2D9GETj92eaLlkH%2FCalendar-and-Time-Tracking%252C-Landscape%3Fpage-id%3D401%253A12854%26node-id%3D401%253A19179%26viewport%3D720%252C577%252C0.27%26scaling%3Dscale-down-width" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe>
               </div> 
            </div>
         </div>
      </div>
   );
}
export default Calendar;