import React from "react";
import Modal from "react-modal";
import Inventory from "../Inventory";

import "../styles/Modal.css";
import background from "../images/backgrounds/paper01h.jpg";
import ModalHeaderInventory from '../images/header/Modal-Header-Inventory2.png';

Modal.setAppElement("#root");

function InventoryModal() {

   return (
      <div className="masterModal">
         <div className="modalUpper">
         </div>
         <div className="modalDowner" style={{ backgroundImage: `url(${background})` }}>
            <div className="modalStitch">
               <div className="modalGuts">
                  <Inventory />
               </div>
            </div>
         </div>
      </div>
  );
}

export default InventoryModal;