import React, { useState } from 'react';
import cardImages from '../cardImages';
import cardTitles from '../cardTitles';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import CardInventoryHead from '../modals/cardsHead';

const CharacterLeft = ({ drag, drop, allowDrop }) => {
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  return (
    <div id="CharacterLeft" className="CharacterBox column">
      <div id="Equipment" className="d-flex flex-column align-self-start">
        <div className="mb-2">
          <img src={cardTitles.titles.equipment} draggable="false" width="175" alt="" />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="">
            <div className="card-holder card-holder-vertical" id="CardHolderRing" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="ring" style={{ backgroundImage: `url(${cardImages.cardBacks.equipment.ring})` }}></div>
          </div>
          <div className="align-content-center d-flex flex-column">
            {/* <div className="card-holder card-holder-vertical" id="CardHolderHead" onClick={toggle} onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="head" style={{ backgroundImage: `url(${cardImages.cardBacks.equipment.head})` }}>
              <Modal isOpen={modal} toggle={toggle} className="modal-right">
                <CardInventoryHead drag={drag} drop={drop} allowDrop={allowDrop} />
              </Modal>
            </div> */}
            <div className="card-holder card-holder-vertical" id="CardHolderHead" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="head" style={{ backgroundImage: `url(${cardImages.cardBacks.equipment.head})` }}></div>
            <div className="card-holder card-holder-vertical" id="CardHolderBody" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="body" style={{ backgroundImage: `url(${cardImages.cardBacks.equipment.body})` }}></div>
          </div>
          <div className="">
            <div className="card-holder card-holder-vertical" id="CardHolderFeet" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="feet" style={{ backgroundImage: `url(${cardImages.cardBacks.equipment.feet})` }}></div>
          </div>
        </div>
      </div>
      <div id="Weapons" className="d-flex flex-column align-self-center">
        <div className="mb-2 mt-4">
          <img src={cardTitles.titles.weapons} draggable="false" width="175" alt="" />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className="align-content-center d-flex flex-row weaponx">
            <div className="weaponx-card card-holder-vertical" id="CardHolderHandMain" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="handMain" style={{ backgroundImage: `url(${cardImages.cardBacks.weapons.handMain})` }}></div>
            <div className="weaponx-card card-holder-vertical" id="CardHolderHandOff" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="handOff" style={{ backgroundImage: `url(${cardImages.cardBacks.weapons.handOff})` }}></div>
          </div>
          <div className="">
            <div className="card-holder card-holder-vertical" id="CardHolderHandTwo" onDrop={(ev) => drop(ev)} onDragOver={(ev) => allowDrop(ev)} data-accept-type="handTwo" style={{ backgroundImage: `url(${cardImages.cardBacks.weapons.handTwo})` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterLeft;
