import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import "./styles/Journal.css"

// import background from "./images/backgrounds/book-paper-1200.jpg";

function Journal() {


   return (
      <div className="journal">
            
            <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
               <TabList>
                  <Tab>Task List</Tab>
                  <Tab>Personal Notes</Tab>
                  <Tab>Team Journal</Tab>
               </TabList>

               <TabPanel>
               {/* <div className="tabGuts" style={{ backgroundImage: `url(${background})` }}> */}
                  <div className="tabGuts">
                     <p>This will be a list of tasks and their descriptions.</p>
                  </div>
               </TabPanel>

               <TabPanel>
                  <div className="tabGuts">
                     <p>This will be a note taking app that is only accessible by the player.</p>
                  </div>
               </TabPanel>
               
               <TabPanel>
                  <div className="tabGuts">
                     <p>Eventually this will be a series of journal entries, accessible and editable by anyone in the party. For now, we'll just embed the Google Sheet we've been using.</p>
                     <iframe title="Team Journal" className="team-journal" src="https://docs.google.com/document/d/125vHfRSUW9qXxuRC09_zihyc2cl34S689sVJW0VYYpg/edit#"></iframe>
                  </div>
               </TabPanel>

            </Tabs>
            
         </div>
      );
   }
export default Journal;