import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import "./styles/Journal.css"

function Maps() {


   return (
      <div className="maps">
            
            <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
               <TabList>
                  <Tab>World Map</Tab>
                  <Tab>Seahold</Tab>
               </TabList>

               <TabPanel>
               {/* <div className="tabGuts" style={{ backgroundImage: `url(${background})` }}> */}
                  <div className="tabGuts">
                     <div className="modalIframeOverrides1">
                        <div className="modalIframeOverrides2">
                           <iframe className="modalIframeOverrides3" title="Drenwal, Map of The World" frameborder="0" width="" height="" src="https://view.genial.ly/63efff781770b50011412059" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe>
                        </div> 
                     </div>
                  </div>
               </TabPanel>

               <TabPanel>
                  <div className="tabGuts">
                     <div className="modalIframeOverrides1">
                        <div className="modalIframeOverrides2">
                           <iframe className="modalIframeOverrides3" title="Seahold Map" frameborder="0" width="" height="" src="https://view.genial.ly/620e99973c977a00114e54fb" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> 
                           
                        </div> 
                     </div>
                  </div>
               </TabPanel>

            </Tabs>
            
         </div>
      );
   }
export default Maps;