import React from "react";
import Modal from "react-modal";
import People from "../People";

import "../styles/Modal.css";
import background from "../images/backgrounds/paper01h.jpg";
import ModalHeaderReference from '../images/header/Modal-Header-People2.png';

Modal.setAppElement("#root");

function PeopleModal() {

   return (
      <div className="masterModal">
         <div className="modalUpper">
            <img className="modalTitle" src={ModalHeaderReference} alt="Adventures In Drenwal" />
         </div>
         <div className="modalDowner" style={{ backgroundImage: `url(${background})` }}>
            <div className="modalStitch">
               <div className="modalGuts">
                  <People />
               </div>
            </div>
         </div>
      </div>
  );
}

export default PeopleModal;