import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import DragDrop from "./DragDrop";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/Dashboard.css";
import "./styles/Modal.css";

// import background from "./images/backgrounds/paper01h.jpg";
import background from "./images/backgrounds/book-paper-2560.jpg";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

   return (
      <div className="dashboard bkg-centered" style={{ backgroundImage: `url(${background})` }}>
         <Header />
         <div className="dashboard__container">
            <div className="container-fluid" style={{ maxWidth: "1200px" , margin: "50px auto 0" }}>
               <div className="row" style={{ maxHeight: "100%" , overflow: "scroll" }}>
                  <DragDrop />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}


export default Dashboard;