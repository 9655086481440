import React from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import "./styles/Journal.css"

// import background from "./images/backgrounds/book-paper-1200.jpg";

function Inventory() {


   return (
      <div className="journal">
            
            <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
               <TabList>
                  <Tab>Equiped</Tab>
                  <Tab>Personal Inventory</Tab>
                  <Tab>Team Inventory</Tab>
               </TabList>

               <TabPanel>
               {/* <div className="tabGuts" style={{ backgroundImage: `url(${background})` }}> */}
                  <div className="tabGuts">
                     <p>This will be a list of equipment you currently have equiped.</p>
                  </div>
               </TabPanel>

               <TabPanel>
                  <div className="tabGuts">
                     <p>This will be a gallery of the inventory you have stowed in a pack, and organized by pack.</p>
                  </div>
               </TabPanel>
               
               <TabPanel>
                  <div className="tabGuts">
                  <p>This will be how you hand a "card" off to someone else around the table.</p>
                  </div>
               </TabPanel>

            </Tabs>
            
         </div>
      );
   }
export default Inventory;