import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./styles/Login.css";

import BodyBackground from "./images/papers/page02v-sm.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);

   return (
      <div className="login">
         <div className="login__container" style={{ backgroundImage: `url(${BodyBackground})` }}>
            <div className="pt-20 px-20 pb-10">
               <p className="loginFormText mb-15">Thank you for your interest in using my "app"!</p>
               
               <p className="loginFormText mb-15">Hopefully, you've already been added to the DAU (Database of Awesome Users). If you <em>have</em> been added to the app, then log in and have fun! Otherwise you're S.O.L. . . . Sorry, don't have the bandwidth to "gift" the genral public with this yet.</p>
               
               <p className="loginFormText">Interested in financially backing this venture? I accept all forms of currency. Like, literally, any form of currency. Find me on some kind of social network and send me something.</p>
            </div>
            <p className="loginFormTitle mb-30">Good journey</p>
            {/* <input
               type="text"
               className="login__textBox"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               placeholder="E-mail Address"
            />
            <input
               type="password"
               className="login__textBox"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               placeholder="Password"
            />
            <button
               className="login__btn"
               onClick={() => logInWithEmailAndPassword(email, password)}
            >
               Login
            </button> */}
            <button className="login__btn login__google center" onClick={signInWithGoogle}>
               Login with the Google
            </button>
            {/* <div>
               <Link to="/reset">Forgot Password</Link>
            </div> */}
            {/* <div>
               Don't have an account? <Link to="/register">Register</Link> now.
            </div> */}
         </div>
      </div>
  );
}

export default Login;
