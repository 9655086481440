const cardTitles= {
    titles: {
      equipment: require("./images/cards/title-equipment-min.png"),
      bonus: require("./images/cards/title-bonus-min.png"),
      heritage: require("./images/cards/title-heritage-min.png"),
      training: require("./images/cards/title-training-min.png"),
      weapons: require("./images/cards/title-weapons-min.png"),
    },
    modals: {
        inventory: {
            head: require("./images/ModalHeader-Inventory-Head-min.png"),
            body: require("./images/ModalHeader-Inventory-Body-min.png"),
            feet: require("./images/ModalHeader-Inventory-Feet-min.png"),
            ring: require("./images/ModalHeader-Inventory-Ring-min.png"),
        }
    },
  };
  
  export default cardTitles;