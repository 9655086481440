import React from "react";
import Modal from "react-modal";
import Maps from "../Maps";

import "../styles/Modal.css";
import background from "../images/backgrounds/book-paper-1200.jpg";
import ModalHeaderMaps from '../images/header/Modal-Header-Maps2.png';

Modal.setAppElement("#root");

function MapsModal() {

   return (
      <div className="masterModal">
         <div className="modalUpper">
            <img className="modalTitle" src={ModalHeaderMaps} alt="Adventures In Drenwal" />
         </div>
         <div className="modalDowner" style={{ backgroundImage: `url(${background})` }}>
            <div className="modalStitch">
               <div className="modalGuts">
               <Maps />
               </div>
            </div>
         </div>
      </div>
  );
}

export default MapsModal;