const cardImages = {
  cardBacks: {
    equipment: {
      head: require("./images/cards/CardBack-V-Head-min.png"),
      body: require("./images/cards/CardBack-V-Body-min.png"),
      feet: require("./images/cards/CardBack-V-Feet-min.png"),
      ring: require("./images/cards/CardBack-V-Ring-min.png"),
    },
    weapons: {
      handMain: require("./images/cards/CardBack-V-HandMain-min.png"),
      handOff: require("./images/cards/CardBack-V-HandOff-min.png"),
      handTwo: require("./images/cards/CardBack-V-HandTwo-min.png"),
    },
    heritage: {
      dwarf: require("./images/cards/CardBack-H-Heritage-Dwarf-min.png"),
      elf: require("./images/cards/CardBack-H-Heritage-Elf-min.png"),
      halfling: require("./images/cards/CardBack-H-Heritage-Halfling-min.png"),
      human: require("./images/cards/CardBack-H-Heritage-Human-min.png"),
    },
    training: {
      cleric: require("./images/cards/CardBack-V-Head-min.png"),
      knight: require("./images/cards/CardBack-V-Head-min.png"),
      ranger: require("./images/cards/CardBack-V-Head-min.png"),
      rogue: require("./images/cards/CardBack-V-Head-min.png"),
      wizard: require("./images/cards/CardBack-V-Head-min.png"),
    },
  },
  cards: {
    equipment: {
      head: {
        head: require("./images/cards/CardEquip-Head-min.jpg"),
        artifactHatOfThePhoenix: require("./images/cards/CardEquip-Head-Artifact-HatOfThePhoenix-min.jpg"),
        artifactHelmOfTheOwl: require("./images/cards/CardEquip-Head-Artifact-HelmOfTheOwl-min.jpg"),
        helmet1: require("./images/cards/CardEquip-Head-Helmet1-min.jpg"),
        helmet2: require("./images/cards/CardEquip-Head-Helmet2-min.jpg"),
        magicViking: require("./images/cards/CardEquip-Head-Magic-Viking-min.jpg"),
        magicHelmet1: require("./images/cards/CardEquip-Head-MagicHelmet1-min.jpg"),
        wizardHat1: require("./images/cards/CardEquip-Head-WizardHat1-min.jpg"),
        wizardHat2: require("./images/cards/CardEquip-Head-WizardHat2-min.jpg"),
      },
      body: {
        body: require("./images/cards/CardEquip-Body-min.jpg"),
        coatAdventures: require("./images/cards/CardEquip-Body-Coat-Adventures-min.jpg"),
        coatPirate: require("./images/cards/CardEquip-Body-Coat-Pirate-min.jpg"),
        leather1: require("./images/cards/CardEquip-Body-Leather1-min.jpg"),
        leather2: require("./images/cards/CardEquip-Body-Leather2-min.jpg"),
        leather3ForestGuardian: require("./images/cards/CardEquip-Body-Leather3-ForestGuardian-min.jpg"),
        leather4Shadows: require("./images/cards/CardEquip-Body-Leather4-Shadows-min.jpg"),
        leather5Phoenix: require("./images/cards/CardEquip-Body-Leather5-Phoenix-min.jpg"),
        plate1: require("./images/cards/CardEquip-Body-Plate1-min.jpg"),
        plate2: require("./images/cards/CardEquip-Body-Plate2-min.jpg"),
        plate3MountainGuardian: require("./images/cards/CardEquip-Body-Plate3-MountainGuardian-min.jpg"),
        plate4Holy: require("./images/cards/CardEquip-Body-Plate4-Holy-min.jpg"),
        plate5Golden: require("./images/cards/CardEquip-Body-Plate5-Golden-min.jpg"),
        robesWizard1: require("./images/cards/CardEquip-Body-Robes-Wizard1-min.jpg"),
        robesWizard2: require("./images/cards/CardEquip-Body-Robes-Wizard2-min.jpg"),
        robesWizard3Phoenix: require("./images/cards/CardEquip-Body-Robes-Wizard3-Phoenix-min.jpg"),
        robes1: require("./images/cards/CardEquip-Body-Robes1-min.jpg"),
        robes2: require("./images/cards/CardEquip-Body-Robes2-min.jpg"),
        robes3WaveGuardian: require("./images/cards/CardEquip-Body-Robes3-WaveGuardian-min.jpg"),
        robes4Woodland: require("./images/cards/CardEquip-Body-Robes4-Woodland-min.jpg"),
      },
      feet: {
        feet: require("./images/cards/CardEquip-Feet-min.jpg"),
        boots1: require("./images/cards/CardEquip-Feet-Boots1-min.jpg"),
        boots2: require("./images/cards/CardEquip-Feet-Boots2-min.jpg"),
        boots3Jumping: require("./images/cards/CardEquip-Feet-Boots3-Jumping-min.jpg"),
        boots4Pirate: require("./images/cards/CardEquip-Feet-Boots4-Pirate-min.jpg"),
        shoesWinged: require("./images/cards/CardEquip-Feet-Shoes-Winged-min.jpg"),
        shoes1: require("./images/cards/CardEquip-Feet-Shoes1-min.jpg"),
      },
      ring: {
        ring: require("./images/cards/CardEquip-Ring-min.jpg"),
        air1: require("./images/cards/CardEquip-Ring-Air1-min.jpg"),
        air2: require("./images/cards/CardEquip-Ring-Air2-min.jpg"),
        air3: require("./images/cards/CardEquip-Ring-Air3-min.jpg"),
        defense1: require("./images/cards/CardEquip-Ring-Defense1-min.jpg"),
        defense2: require("./images/cards/CardEquip-Ring-Defense2-min.jpg"),
        defense3: require("./images/cards/CardEquip-Ring-Defense3-min.jpg"),
        earth1: require("./images/cards/CardEquip-Ring-Earth1-min.jpg"),
        earth2: require("./images/cards/CardEquip-Ring-Earth2-min.jpg"),
        earth3: require("./images/cards/CardEquip-Ring-Earth3-min.jpg"),
        fire1: require("./images/cards/CardEquip-Ring-Fire1-min.jpg"),
        fire2: require("./images/cards/CardEquip-Ring-Fire2-min.jpg"),
        fire3: require("./images/cards/CardEquip-Ring-Fire3-min.jpg"),
        hitting1: require("./images/cards/CardEquip-Ring-Hitting1-min.jpg"),
        hitting2: require("./images/cards/CardEquip-Ring-Hitting2-min.jpg"),
        hitting3: require("./images/cards/CardEquip-Ring-Hitting3-min.jpg"),
        illusions1: require("./images/cards/CardEquip-Ring-Illusions1-min.jpg"),
        illusions2: require("./images/cards/CardEquip-Ring-Illusions2-min.jpg"),
        illusions3: require("./images/cards/CardEquip-Ring-Illusions3-min.jpg"),
        nature1: require("./images/cards/CardEquip-Ring-Nature1-min.jpg"),
        nature2: require("./images/cards/CardEquip-Ring-Nature2-min.jpg"),
        nature3: require("./images/cards/CardEquip-Ring-Nature3-min.jpg"),
        spying1: require("./images/cards/CardEquip-Ring-Spying1-min.jpg"),
        spying2: require("./images/cards/CardEquip-Ring-Spying2-min.jpg"),
        spying3: require("./images/cards/CardEquip-Ring-Spying3-min.jpg"),
        water1: require("./images/cards/CardEquip-Ring-Water1-min.jpg"),
        water2: require("./images/cards/CardEquip-Ring-Water2-min.jpg"),
        water3: require("./images/cards/CardEquip-Ring-Water3-min.jpg"),
      }
    },
    weapons: {
      handMain: {
        handMain: require("./images/cards/CardWeapon-HandMain-min.jpg"),
        battleAxeDwarven: require("./images/cards/CardWeapon-HandMain-BattleAxe-Dwarven-min.jpg"),
        battleAxeFire: require("./images/cards/CardWeapon-HandMain-BattleAxe-Fire-min.jpg"),
        battleAxe1: require("./images/cards/CardWeapon-HandMain-BattleAxe1-min.jpg"),
        battleAxe2: require("./images/cards/CardWeapon-HandMain-BattleAxe2-min.jpg"),
        dagger1: require("./images/cards/CardWeapon-HandMain-Dagger1-min.jpg"),
        dagger2: require("./images/cards/CardWeapon-HandMain-Dagger2-min.jpg"),
        daggerFire: require("./images/cards/CardWeapon-HandMain-DaggerFire-min.jpg"),
        mace1: require("./images/cards/CardWeapon-HandMain-Mace1-min.jpg"),
        mace2: require("./images/cards/CardWeapon-HandMain-Mace2-min.jpg"),
        mace3Fire: require("./images/cards/CardWeapon-HandMain-Mace3-Fire-min.jpg"),
        shortSword1: require("./images/cards/CardWeapon-HandMain-ShortSword1-min.jpg"),
        shortSword2: require("./images/cards/CardWeapon-HandMain-ShortSword2-min.jpg"),
        shortSword3Fire: require("./images/cards/CardWeapon-HandMain-ShortSword3-Fire-min.jpg"),
        shortSword4FrostyFoil: require("./images/cards/CardWeapon-HandMain-ShortSword4-FrostyFoil-min.jpg"),
        warhammer1: require("./images/cards/CardWeapon-HandMain-Warhammer1-min.jpg"),
        warhammer2: require("./images/cards/CardWeapon-HandMain-Warhammer2-min.jpg"),
        warhammer3Fire: require("./images/cards/CardWeapon-HandMain-Warhammer3-Fire-min.jpg"),
        warhammer4Stone: require("./images/cards/CardWeapon-HandMain-Warhammer4-Stone-min.jpg"),
      },
      handOff: {
        handOff: require("./images/cards/CardWeapon-HandOff-min.jpg"),
        boomerang1: require("./images/cards/CardWeapon-HandOff-Boomerang1-min.jpg"),
        boomerang2: require("./images/cards/CardWeapon-HandOff-Boomerang2-min.jpg"),
        hatchet1: require("./images/cards/CardWeapon-HandOff-Hatchet1-min.jpg"),
        hatchet2: require("./images/cards/CardWeapon-HandOff-Hatchet2-min.jpg"),
        lantern: require("./images/cards/CardWeapon-HandOff-Lantern-min.jpg"),
        shieldBuckler1: require("./images/cards/CardWeapon-HandOff-Shield-Buckler1-min.jpg"),
        shieldBuckler2: require("./images/cards/CardWeapon-HandOff-Shield-Buckler2-min.jpg"),
        shieldBuckler3Bashing: require("./images/cards/CardWeapon-HandOff-Shield-Buckler3-Bashing-min.jpg"),
        shieldIronwood1: require("./images/cards/CardWeapon-HandOff-Shield-Ironwood1-min.jpg"),
        shieldIronwood2: require("./images/cards/CardWeapon-HandOff-Shield-Ironwood2-min.jpg"),
        shieldKite1: require("./images/cards/CardWeapon-HandOff-Shield-Kite1-min.jpg"),
        shieldKite2: require("./images/cards/CardWeapon-HandOff-Shield-Kite2-min.jpg"),
        shieldKite3Dragon: require("./images/cards/CardWeapon-HandOff-Shield-Kite3-Dragon-min.jpg"),
        shieldSteel1: require("./images/cards/CardWeapon-HandOff-Shield-Steel1-min.jpg"),
        shieldSteel2: require("./images/cards/CardWeapon-HandOff-Shield-Steel2-min.jpg"),
        wandBladed: require("./images/cards/CardWeapon-HandOff-Wand-Bladed-min.jpg"),
        wandFire: require("./images/cards/CardWeapon-HandOff-Wand-Fire-min.jpg"),
        wandLife: require("./images/cards/CardWeapon-HandOff-Wand-Life-min.jpg"),
      },
      handTwo: {
        handTwo: require("./images/cards/CardWeapon-HandTwo-min.jpg"),
        crossbow1: require("./images/cards/CardWeapon-HandTwo-Crossbow1-min.jpg"),
        crossbow2: require("./images/cards/CardWeapon-HandTwo-Crossbow2-min.jpg"),
        crossbow3VanHelsing: require("./images/cards/CardWeapon-HandTwo-Crossbow3-VanHelsing-min.jpg"),
        greatAxe1: require("./images/cards/CardWeapon-HandTwo-GreatAxe1-min.jpg"),
        greatAxe2: require("./images/cards/CardWeapon-HandTwo-GreatAxe2-min.jpg"),
        greatAxe3Fire: require("./images/cards/CardWeapon-HandTwo-GreatAxe3-Fire-min.jpg"),
        greatSword1: require("./images/cards/CardWeapon-HandTwo-GreatSword1-min.jpg"),
        greatSword2: require("./images/cards/CardWeapon-HandTwo-GreatSword2-min.jpg"),
        greatSword3Fire: require("./images/cards/CardWeapon-HandTwo-GreatSword3-Fire-min.jpg"),
        longbow1: require("./images/cards/CardWeapon-HandTwo-Longbow1-min.jpg"),
        longbow2: require("./images/cards/CardWeapon-HandTwo-Longbow2-min.jpg"),
        longbow3Elven: require("./images/cards/CardWeapon-HandTwo-Longbow3-Elven-min.jpg"),
        maul1: require("./images/cards/CardWeapon-HandTwo-Maul1-min.jpg"),
        maul2: require("./images/cards/CardWeapon-HandTwo-Maul2-min.jpg"),
        maul3Fire: require("./images/cards/CardWeapon-HandTwo-Maul3-Fire-min.jpg"),
        shortBow1: require("./images/cards/CardWeapon-HandTwo-ShortBow1-min.jpg"),
        shortBow2: require("./images/cards/CardWeapon-HandTwo-ShortBow2-min.jpg"),
        spear1: require("./images/cards/CardWeapon-HandTwo-Spear1-min.jpg"),
        spear2: require("./images/cards/CardWeapon-HandTwo-Spear2-min.jpg"),
        spear3Point: require("./images/cards/CardWeapon-HandTwo-Spear3-Point-min.jpg"),
        trident1: require("./images/cards/CardWeapon-HandTwo-Trident1-min.jpg"),
        trident2: require("./images/cards/CardWeapon-HandTwo-Trident2-min.jpg"),
        trident3Deep: require("./images/cards/CardWeapon-HandTwo-Trident3-Deep-min.jpg"),
      },
    },
    
  },
};

export default cardImages;